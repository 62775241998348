import languageData from '../constants/langJson.js'

/**
 * 获取对应语言下的文本
 * @param {languageName} 数据类型 语言版本
 * @param {name} 语言key
 */
export function getLanguageText(languageName = 'zh', name) {
    let data = languageData[languageName]
    try {
        return data[name] || name
    } catch {
        return name
    }
  }