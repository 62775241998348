<template>
  <div>
    <div class="computer">
      <div class="header">
        <!-- <img class="logo" src="http://static.parceljet.com/logo.png" alt="品晟官网" @click="goHome"> -->
        <img class="logo" src="../assets/images/新logo.png" alt="品晟官网" @click="goHome">
        <nav class="nav">
          <div :class="['nav-item', {'curr': item.id == currId}]" v-for="item of navList" :key="item.id" @click="handleClickNav(item.id, item.url, item.hover = 1)"  @mouseover="item.hover = true" @mouseleave="item.hover = false">
            {{getLanguageText(languageName, item.name)}}
            <transition v-on:before-enter="beforeEnter"
              v-on:enter="enter"
              v-on:after-enter="afterEnter"
              v-on:before-leave="beforeLeave"
              v-on:leave="leave"
              v-on:after-leave="afterLeave"
              v-if="item.subNav">
              <div class="sub-nav" v-show="item.hover" :key="item.id">
                <div :class="['sub-nav-item', {'sub-curr': item.id == currId}]" v-for="value of item.subNav" :key="value.id" @click.stop="handleClickNav(item.id, value.url, value.num)">{{getLanguageText(languageName, value.name)}}</div>
              </div>
            </transition>
          </div>
        </nav>
          <div class="langStyle03">
              <div class="lang_main">
                  <div class="top_box imgSet">
                    <el-dropdown @command="languageCommand" placement="bottom" class="dropdown">
                      <span class="el-dropdown-link">
                        {{language}}
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in languageList" :key="index" :command="item.value">{{item.name}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <span class="el-icon-chat-line-square" style="margin: 0 10px;font-size: 30px;" @click="getGoComplaint()"></span>
                    <el-badge value="0" class="badge">
                      <span class="el-icon-message-solid badge_icon"></span>
                    </el-badge>
                  </div>
                  <div class="lang_content bgareaSet" v-show="butShow">
                      <!-- 语言列表 -->
                    <a class="lang-item textSet" @click="getLanguage(1)">简体中文</a>
                    <a class="lang-item textSet" @click="getLanguage(2)">English</a>
                  </div>
              </div>
          </div>
        
      </div>
    </div>
    <div class="phone">
      <div class="p_header">
        <img class="logo p20 imgh" src="../assets/images/新logo.png" alt="品晟官网" @click="goHome">
        <!-- <div class="p_header_pl">{{node.siteName}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { sitehome } from '../api/index'
export default {
  name: 'Header',
  data() {
    return {
      currId: 1,
      navShow: false,
      butShow: false,
      language: '中文',
      languageName: 'zh',
      languageList: [   // 多语言数据
        { value: 'WWW', name: '中文', languageName: 'zh' },
        { value: 'WWWE', name: 'English', languageName: 'en' },
        { value: 'DE', name: 'German', languageName: 'de' },
        { value: 'FR', name: 'Français', languageName: 'fr' },
      ]
    }
  },
  computed: {
    navList(){
      return this.$store.state.navList
    },
    newsCategory(){
        return this.$store.state.newsCategory
    },
    node(){
        return this.$store.state.node
    },
    languageType(){
        return this.$store.state.languageType
    },
    siteCode(){
      return this.$store.state.siteCode
    },
  },
  created() {
    this.currId = sessionStorage.getItem('currId') || 1
  },
  mounted() {
    if(this.$store.state.siteCode == 'WWW' || this.$store.state.siteCode == 'www') {
      this.language = '中文'
      this.languageName = 'zh'
    } else if(this.$store.state.siteCode == 'WWWE' || this.$store.state.siteCode == 'wwwe') {
      this.language = 'English'
      this.languageName = 'en'
    } else if(this.$store.state.siteCode == 'DE' || this.$store.state.siteCode == 'de') {
      this.language = 'German'
      this.languageName = 'de'
    } else if(this.$store.state.siteCode == 'FR' || this.$store.state.siteCode == 'fr') {
      this.language = 'Français'
      this.languageName = 'fr'
    }
  },
  methods: {
    // 语言切换
    languageCommand(val) {
      this.languageList.map(item => {
        if(item.value == val) {
          this.language = item.name
          this.languageName = item.languageName
          this.getLanguage(item.languageName, item.value)
          this.$forceUpdate()
        }
      })
    },
    getGoComplaint() {
      this.$router.push('/Complaint')
    },
    langMain() {
      this.butShow = !this.butShow;
    },
    getLanguage(i, code) {
      this.$store.commit('getsiteCode', code)
      this.$store.commit('getLanguageType', i)
      this.butShow = false;
      this.getSitehome()
      this.$router.push('/index')
    },
    // 获取首页数据
    getSitehome() {
      let siteCode = this.$store.state.siteCode
      sitehome(siteCode).then((res) => {
        if(res && res.code == 0) {
          let node = res.data.node  // 官网翻译数据
          document.title = node.siteName
          let newsList = {
            newsList_0: {},
            newsList_1: [],
            newsList_all: []
          } // 首页新闻资讯
          let bizList = res.data.bizList // 首页主营业务
          let homeList = []  // 首页横幅
          let about = {}  // 首页公司简介
          let navList = [] //头部菜单
          let businessCategorylist = [] // 主营业务项
          let newsCategorylist = [] // 新闻资讯菜单
          navList = [
            {id: '0', name: '首页', url: '/index', subNav: [], hover: false},
            {id: '1', name: '公司简介', url: '/about', subNav: [
              { id: '12', name: '愿景使命价值观', url: '/about', num: 7 },
              { id: '11', name: '发展历程', url: '/about', num: 5 },
              { id: '13', name: '我们的优势服务', url: '/about', num: 3 },
              { id: '9', name: '物流服务范围', url: '/about', num: 0 },
              // { id: '10', name: '服务团队', url: '/about', num: 3 },
              // { id: '14', name: '我们的服务改善', url: '/about', num: 10 },
              // { id: '15', name: '团队分布', url: '/about', num: 13 },
            ], hover: false},
            {id: '2', name: '合作伙伴招商', url: '/partners', subNav: [], hover: false},
            {id: '3', name: '物流服务产品', url: '/business', subNav: [
              // { id: '16', name: '运营团队', url: '/business', num: 0 },
              // { id: '17', name: '海外仓', url: '/business', num: 0 },
              // { id: '18', name: 'B2BB2C', url: '/business', num: 3 },
              // { id: '19', name: '逆向物流', url: '/business', num: 4 },
              // { id: '20', name: '设施分布', url: '/business', num: 5 },
            ], hover: false},
            // {id: '4', Ename: 'News', name: '服务动态及新闻', url: '/information', subNav: [], hover: false},
            {id: '6', name: '投诉与建议', url: '/Complaint', subNav: [
              { id: '6', name: '投诉与建议', url: '/Complaint' },
              { id: '21', name: 'QA', url: '/qa' },
              { id: '22', name: '总经办信箱', url: '/bossemail' },
            ], hover: false},
            {id: '7', name: '联系我们', url: '/contactUs', subNav: [], hover: false},
            {id: '8', name: '客户登录', url: '/customerLogin', subNav: [], hover: false},
          ];
          let businessCategory = JSON.parse(node.businessCategory)
          let newsCategory = JSON.parse(node.newsCategory)
          // 主营业务菜单
          businessCategory.map(item => {
            /* navList[1].subNav.push(
              { name: item.name, url: '/business', num: item.code }
            ) */
            businessCategorylist.push(
              {
                name:item.name,
                code: item.code
              }
            )
          })
          // 新闻资讯菜单
          newsCategory.map(item => {
            newsCategorylist.push(
              { name: item.name, id: item.code.toString()}
            )
          })
          res.data.homeList.map(item => {
            let imgs = JSON.parse(item.imgs)
            imgs.map(v => {
              homeList.push(
                {
                  url: this.imgTransition(v.urlName),
                  href: v.href,
                  name: v.urlName
                }
              )
            })
          })
          // 首页新闻资讯
          res.data.newsList.map((item, index) => {
            if (index == 0) {
              newsList.newsList_0 = {
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              }
            } else if(index > 0 && index <= 3) {
              newsList.newsList_1.push({
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              })
            }
            newsList.newsList_all.push({
              id: item.id,
              title: item.title,
              bizSynopsis: item.bizSynopsis,
              createTime: this.getDateStr(item.createTime).slice(0, 11),
              imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
              href: JSON.parse(item.imgs)[0].href
            })
          })
          // 首页公司简介
          if(res.data.about) {
            about = {
              aboutSynopsis: res.data.about.aboutSynopsis,
              img: this.imgTransition(JSON.parse(res.data.about.imgs)[0].urlName),
              href: JSON.parse(res.data.about.imgs)[0].href
            }
          }
          // 首页联系我们
          let relationList = [
            {id: 1, name: node.phoneTranslate, icon: 'el-icon-phone-outline', text: node.phoneValue},
            {id: 2, name: node.emailTranslate, icon: 'el-icon-message', text: node.emailValue},
            {id: 3, name: node.addressTranslate, icon: 'el-icon-location-information', text: node.addressValue},
          ]
          this.$store.commit('getNode', node)
          this.$store.commit('getNewsList', newsList)
          this.$store.commit('getBizList', bizList)
          this.$store.commit('getAbout', about)
          this.$store.commit('getNavList', navList)
          this.$store.commit('getHomeList', homeList)
          this.$store.commit('getBusinessCategory', businessCategorylist)
          this.$store.commit('getNewsCategory', newsCategorylist)
          this.$store.commit('getRelationList', relationList)
        }
      }).catch(err => {
        console.log('err', err.response);
        if(err.response.status == 500) {
          this.$store.commit('getsiteCode', 'www')
          this.getSitehome()
        }
      })
    },
    // 图片路径转换
    imgTransition(str) {
      return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
    },
    goHome() {
      this.currId = 0
      this.$store.commit('setId', 0)
      sessionStorage.setItem('currId', 0)
      sessionStorage.setItem('menu', '0')
      this.$router.push('/index')
    },
    handleClickNav(id, url, num = 0) {
      if (id == 1 || id == 3) {
        if(id == 1) {
          this.$store.commit('setLift', num)
        } else {
          this.$store.commit('setLiftBusinessNum', num)
        }
        this.currId = id
        this.$store.commit('setId', id)
        sessionStorage.setItem('currId', id)
        this.$router.push(url)
      } else {
        this.currId = id
        this.$store.commit('setId', id)
        sessionStorage.setItem('currId', id)
        this.$router.push(url)
      }
    },
    beforeEnter(el){
      el.className=el.className+' my-transition'
      el.style.height = '0'
      // 缺少处理了 overflow:hidden 样式的添加，完整组件需要添加此处理，在动画结束后恢复原来的oveflow样式。
    },
    enter(el){
      el.style.height = (el.scrollHeight*100/1920) + 'vw'
    },
    afterEnter(el){
      el.className = el.className.replace("my-transition",'')
      el.style.height = ''
    },
    beforeLeave(el){
      el.style.height = (el.scrollHeight*100/1920) + 'vw'
    },
    leave(el){
      el.className = el.className + ' my-transition'
      el.style.height = 0
    },
    afterLeave(el){
      el.className = el.className.replace("my-transition",'')
      el.style.height = ''
    },
    getDateStr(seconds){
      var date = new Date(seconds)
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var currentTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return currentTime
    }
  },
  watch: {
    '$route': function(value) {
      let curr = this.$router.options.routes.find(item => item.name && item.name === value.name )
      this.currId = curr ? curr.meta.id : 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.slide-fade-enter-active {transition: all .3s ease;}
.slide-fade-leave-active {transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);}
.slide-fade-enter, .slide-fade-leave-to{transform: translateX(100px); opacity: 0;}
.my-transition{transition: .4s height ease-in /* , 10.5s padding-top ease-in-out, 10.5s padding-bottom ease-in-out */}
.dropdown {
  color: #0754fa;
  font-size: 16px;
}
.el-dropdown-menu__item {
  font-size: 16px;
}
.icon_border {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #0754fa;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
}
.icon_text {
  // font-size: 16px;
  // color: rgb(45,140,240);
}
.icon_text1 {
  // font-size: 18px;
  // color: rgb(45,140,240);
}
.langStyle03 {
  height: 40px;
  width: 100px;
  line-height: 40px;
  text-align: center;
  margin-top: 6px;
  flex-grow: 2;
}
.langStyle03 .lang_main {
  font-size: 16px;
  // color: rgb(45,140,240);
  color: rgb(7, 84, 250);
  text-align: center;
  height: 100%;
}
.top_box {
  // display: inline-block;
  // position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // background-color: rgb(45,140,240);
  border-radius: 5px;
  cursor: pointer;
  .badge_icon {
    font-size: 24px;
    color: red;
  }
}
.lang_content {
  font-size: 14px;
  text-align: center;
  width: 150px;
  margin-top: 10px;
  background: #f2f2f2;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.lang_content a {
    display: block;
    line-height: 2.5;
    border-bottom: 1px solid #ccc;
    color: #000;
    cursor: pointer;
}
.lang_content a:hover {
    background-color: #005FBF;
    color: #fff;
}
.header {
  width: 100%;
  height: 74px;
  // background: rgb(216, 236, 248);
  background-color: #fff;
  opacity: 0.9;
  box-shadow: 5px 5px 10px #888888;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // padding: 0 258px;
  padding: 0 260px 0 230px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .logo {
    width: 80px;
    // height: 34px;
    cursor: pointer;
    margin-right: 30px;
  }
  .nav {
    min-width: 700px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-item {
      // flex: 1;
      height: 84px;
      line-height: 84px;
      font-size: 18px;
      font-weight: 500;
      color: #1c1c1c;
      text-align: center;
      margin-right: 20px;
      cursor: pointer;
      position: relative;
      &:hover {
        // color: #036efaea;
        color: rgb(7, 84, 250);
      }
      &:last-child {
        margin-right: 0;
      }
      &.curr {
        // color: #3782d8ea;
        color: rgb(7, 84, 250);
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 14px;
          width: 13px;
          height: 3px;
          border-bottom: 1px solid #3782d8ea;
        }
      }
      .sub-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 78px;
        width: 246px;
        background: #fff;
        overflow: hidden;
        .sub-nav-item {
          width: 100%;
          height: 53px;
          line-height: 53px;
          font-size: 18px;
          color: #666;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: #3782d8;
          }
        }
      }
    }
  }
  .hoverAnimation-enter-active {
    animation: fadeInDown .5s;
  }
  .hoverAnimation-leave-active {
    animation: fadeInDown .5s reverse;
  }
   @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-50%, -5%, 0);
      transform: translate3d(-50%, -5%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(-50%, 0, 0);
      transform: translate3d(-50%, 0, 0);
    }
  }
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
}
.p_header {
  width: 100%;
  height: 14vw;
  display: flex;
  padding: 20px 40px 0 20px;
  justify-content: flex-start;
  line-height: 14vw;
  z-index: 99;
  .imgh {
    height: 100%;
  }
  .p_header_pl {
    padding-left: 6vw;
    color: #409eff;
    text-align: center;
  }
}
@media screen and (min-width: 1100px) {
  .langStyle03 {
    width: 120px !important;
  }
}
@media screen and (max-width: 1300px) {
  .nav-item {
    font-size: 18px !important;
  }
  .sub-nav-item {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1400px) {
  .nav-item {
    font-size: 18px !important;
  }
  .sub-nav-item {
    font-size: 18px !important;
  }
}
</style>
