import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui';
import filters from '@/utils/filters';

// 创建axios实例
// let baseURL = process.env.VUE_APP_API_ROOT;
const service = axios.create({
  // baseURL: baseURL,
  timeout: 1000 * 60,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
  }
})

// request拦截器
service.interceptors.request.use(config => {
  // config.headers['token'] = localStorage.getItem('token')
  // config.headers['Range'] =  'bytes=0-'
  return config
}, error => {
  return Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(response => {
  if (response.data) {
    if (response.data.code === 401) { // 401, token失效
      localStorage.removeItem('token')
      document.cookie = `JSESSIONID='';domain=.aoyuan.net;`
      router.push({ name: 'login' })
    } else if (response.data.code === 4001) { // 没权限|| ip被禁
      router.push({ name: 'noPermission' })
    } else if (response.data.code === 500) {
      router.push({ name: 'Index' })
    } else if(response.data.code === 0) {
      // gzip 是否解压数据
      if(response.data.gzip) {
        console.time('转换耗时');
        let unzipData = JSON.parse(filters.unzip(response.data.gzipData))
        console.timeEnd('转换耗时');
        response.data.data = unzipData
      }
    }
  }
  return response.data
}, error => {
  if(error.response) {
    if(error.response.status == 500 || error.response.status == 400 || error.response.status == 403) {
        if(error.response.data.message && error.response.data.message != 'siteCode is null') {
            Message.error(error.response.data.message)
        } else {
            // 导出抛错
            if (error.response.data.type === 'application/json') {
                const fileReader = new FileReader();
                fileReader.readAsText(error.response.data, 'utf-8');
                fileReader.onload = function () {
                    const result = fileReader.result
                    const msg = result ? JSON.parse(result).message : ''
                    Message({ message: msg || '导出文件失败', type: 'error' });
                };
            }
        }
    } else {
        Message.error('请求失败,请重新请求！')
    }
} else {
    if(error.message == '取消请求') {
        
    }
}
  return Promise.reject(error)
})

export default service
