exports.formatMoney = (value) => {
  if (value) {
    value = Number(value);
    return '￥ ' + value.toFixed(2);
  }
};

/**
 * //展示字典的值 
 * @param {*} val 第一个参数值
 * @param {*} dicList 做参照的数组
 * @param {*} type 如果是默认的字典类型无需输入，其他为['要输出的显示值字段名称','对应的id字段名称']
 */
exports.labelMapper = (val, dicList, type) => {
  for (var k in dicList) {
    if (type && type.length != 0) {
      if (dicList[k][type[1]] == val) {
        return dicList[k][type[0]];
      }
    }
    else {
      if (dicList[k]['factValue'] == val) {
        return dicList[k]['displayValue'];
      }
    }
  }
  return val;
}

/**
 * 格式化时间
 * @param {string|number|object|Array} dateTime - 时间，可以是一个字符串、时间戳、表示时间的对象、Date对象或者******表示时间的数组
 * @param {string} [fmt] - 格式
 * @returns {string} 返回格式化后的日期时间，默认格式：2018年1月11日 15:00
 * @see [momentjs]{@tutorial http://momentjs.cn/}
 */
exports.formatDate = (time, fmt, def = "") => {
  if (time == "" || time == 'undefined' || time === undefined) {
    return def
  }
  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
};

exports.stringSub = (str,n) => {
  if (str) {
    if (str.replace(/[\u4e00-\u9fa5]/g, "**").length <= n) {
      return str;
    }
    else {
      var len = 0;
      var tmpStr = "";
      for (var i = 0; i < str.length; i++) {//遍历字符串
        if (/[\u4e00-\u9fa5]/.test(str[i])) {//中文 长度为两字节
          len += 2;
        }
        else {
          len += 1;
        }
        if (len > n) {
          break;
        }
        else {
          tmpStr += str[i];
        }
      }
      return tmpStr + " ...";
    }
  }
};


exports.toFixed = (str,num) => {
  return str.toFixed(num)
}

//解压
exports.unzip = (strData) => {
  var charData = strData.split('').map(function(x){return x.charCodeAt(0);});
  var binData = new Uint8Array(charData);
  var data = pako.inflate(binData);
  return Utf8ArrayToStr(data);
}

//解决数据过大和中文乱码
function Utf8ArrayToStr (array) {
  var out, i, len, c;
  var char2, char3;
  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
    c = array[i++];
    switch(c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
      case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
      case 14:
      // 1110 xxxx  10xx xxxx  10xx xxxx
      char2 = array[i++];
      char3 = array[i++];
      out += String.fromCharCode(((c & 0x0F) << 12) |
        ((char2 & 0x3F) << 6) |
        ((char3 & 0x3F) << 0));
      break;
    }
  }
  return out;
}

