<template>
  <div id="app">
    <Header class="app_header" v-if="isClock" />
    <router-view :key="$route.path" :style="{'margin-top': isShow ? marginT : ''}"/>
    <div class="service" @click="getGoContactUs()" title="在线客服">
      <span class="el-icon-service"></span>
    </div>
    <Footer v-if="isShow" />
  </div>
</template>

<script>
import './assets/css/index.scss'
import 'aos/dist/aos.css'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { sitenodeInfo, sitehome } from './api/index'

export default {
  name: 'App',
  data () {
    return {
      num: 2,
      siteCode: '',
      marginT: '40px'
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    isShow(){
      let maxResult = window.matchMedia('(max-width: 981px)');
      if (maxResult.matches) {
        return false
      } else {
        return this.$route.path !== '/index' && this.$route.path !== '/' && this.$route.path !== '/demo'
      }
    },
    isClock(){
      return this.$route.path !== '/demo'
    }
  },
  created() {
    this.getHost()
  },
  mounted() {
    this.getSitehome()
    let maxResult = window.matchMedia('(max-width: 980px)');
    if (maxResult.matches) {
      this.marginT = '5px'
    } else {
      this.marginT = '0px'
    }
  },
  methods: {
    getGoContactUs() {
      this.$router.push('/contactUs')
    },
    // 获取域名上的二字码
    getHost(){
      let str = this.getQueryString('siteCode')
      let hostStr = window.location.host;
      let index = hostStr.indexOf('.')
      
      if(str) {
        this.siteCode = str
      } else {
        if(hostStr.slice(0, index) == 'parceljet' || hostStr.slice(0, index) == 'localhost:808' || hostStr.slice(0, index) == '192') {
          this.siteCode = 'www'
        } else {
          this.siteCode = hostStr.slice(0, index)
        }
      }
      if(sessionStorage.getItem('languageType')) {
        this.siteCode = sessionStorage.getItem('languageType')
        if(sessionStorage.getItem('languageType') == 'zh') {
          this.$store.commit('getLanguageType', 'zh')
          this.siteCode = 'www'
        } else if(sessionStorage.getItem('languageType') == 'en') {
          this.siteCode = 'wwwe'
        } else {
          this.siteCode = sessionStorage.getItem('languageType')
        }
      } else {
        // 获取站点语言，显示
        if(this.siteCode == 'WWW' || this.siteCode == 'www') {
          this.$store.commit('getLanguageType', 'zh')
          this.siteCode = 'www'
        } else if(this.siteCode == 'WWWE' || this.siteCode == 'wwwe') {
          this.$store.commit('getLanguageType', 'en')
          this.siteCode = 'wwwe'
        } else if(this.siteCode == 'DE' || this.siteCode == 'de') {
          this.$store.commit('getLanguageType', 'de')
          this.siteCode = 'de'
        } else if(this.siteCode == 'FR' || this.siteCode == 'fr') {
          this.$store.commit('getLanguageType', 'fr')
          this.siteCode = 'fr'
        }
        // 获取浏览器默认语言
        let lang = navigator.language;
        console.log("🚀 ~ getHost ~ lang:", lang.slice(0,2))
        if(lang.slice(0,2) == 'zh') {
          this.$store.commit('getLanguageType', 'zh')
          this.siteCode = 'www'
        } else if(lang.slice(0,2) == 'en') {
          this.$store.commit('getLanguageType', 'en')
          this.siteCode = 'wwwe'
        } else if(lang.slice(0,2) == 'de') {
          this.$store.commit('getLanguageType', 'de')
          this.siteCode = 'de'
        } else if(lang.slice(0,2) == 'fr') {
          this.$store.commit('getLanguageType', 'fr')
          this.siteCode = 'fr'
        }
      }
      console.log("🚀 ~ file: 国家二字码 ", this.siteCode)
      this.$store.commit('getsiteCode', this.siteCode)
    },
    getQueryString(variable) {
      var query = window.location.hash.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },
    // 获取首页数据
    getSitehome() {
      let siteCode = this.$store.state.siteCode
      sitehome(siteCode).then((res) => {
        if(res && res.code == 0) {
          let node = res.data.node  // 官网翻译数据
          document.title = node.siteName
          let newsList = {
            newsList_0: {},
            newsList_1: [],
            newsList_all: [],
          } // 首页新闻资讯
          let bizList = res.data.bizList // 首页主营业务
          let homeList = []  // 首页横幅
          let about = {}  // 首页公司简介
          let navList = [] //头部菜单
          let businessCategorylist = [] // 主营业务项
          let newsCategorylist = [] // 新闻资讯菜单
          navList = [
            {id: '0', name: '首页', url: '/index', subNav: [], hover: false},
            {id: '1', name: '公司简介', url: '/about', subNav: [
              { id: '12', name: '愿景使命价值观', url: '/about', num: 7 },
              { id: '11', name: '发展历程', url: '/about', num: 5 },
              { id: '13', name: '我们的优势服务', url: '/about', num: 3 },
              { id: '9', name: '物流服务范围', url: '/about', num: 0 },
              // { id: '10', name: '服务团队', url: '/about', num: 3 },
              // { id: '14', name: '我们的服务改善', url: '/about', num: 10 },
              // { id: '15', name: '团队分布', url: '/about', num: 13 },
            ], hover: false},
            {id: '2', name: '合作伙伴招商', url: '/partners', subNav: [], hover: false},
            {id: '3', name: '物流服务产品', url: '/business', subNav: [
              // { id: '16', name: '运营团队', url: '/business', num: 0 },
              // { id: '17', name: '海外仓', url: '/business', num: 0 },
              // { id: '18', name: 'B2BB2C', url: '/business', num: 3 },
              // { id: '19', name: '逆向物流', url: '/business', num: 4 },
              // { id: '20', name: '设施分布', url: '/business', num: 5 },
            ], hover: false},
            // {id: '4', Ename: 'News', name: '服务动态及新闻', url: '/information', subNav: [], hover: false},
            {id: '6', name: '投诉与建议', url: '/Complaint', subNav: [
              { id: '6', name: '投诉与建议', url: '/Complaint' },
              { id: '21', name: 'QA', url: '/qa' },
              { id: '22', name: '总经办信箱', url: '/bossemail' },
            ], hover: false},
            {id: '7', name: '联系我们', url: '/contactUs', subNav: [], hover: false},
            {id: '8', name: '客户登录', url: '/customerLogin', subNav: [], hover: false},
          ];
          let businessCategory = JSON.parse(node.businessCategory)
          let newsCategory = JSON.parse(node.newsCategory)
          // 主营业务菜单
          businessCategory.map(item => {
            /* navList[1].subNav.push(
              { name: item.name, url: '/business', num: item.code }
            ) */
            businessCategorylist.push(
              {
                name:item.name,
                code: item.code
              }
            )
          })
          // 新闻资讯菜单
          newsCategory.map(item => {
            newsCategorylist.push(
              { name: item.name, id: item.code.toString()}
            )
          })
          res.data.homeList.map(item => {
            let imgs = JSON.parse(item.imgs)
            imgs.map(v => {
              homeList.push(
                {
                  url: this.imgTransition(v.urlName),
                  href: v.href,
                  name: v.urlName
                }
              )
            })
          })
          // 首页新闻资讯
          res.data.newsList.map((item, index) => {
            if (index == 0) {
              newsList.newsList_0 = {
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              }
            } else if(index > 0 && index <= 3) {
              newsList.newsList_1.push({
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              })
            }
            newsList.newsList_all.push({
              id: item.id,
              title: item.title,
              bizSynopsis: item.bizSynopsis,
              createTime: this.getDateStr(item.createTime).slice(0, 11),
              imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
              href: JSON.parse(item.imgs)[0].href
            })
          })
          // 首页公司简介
          if(res.data.about) {
            about = {
              aboutSynopsis: res.data.about.aboutSynopsis,
              img: this.imgTransition(JSON.parse(res.data.about.imgs)[0].urlName),
              href: JSON.parse(res.data.about.imgs)[0].href
            }
          }
          // 首页联系我们
          let relationList = [
            {id: 1, name: node.phoneTranslate, icon: 'el-icon-phone-outline', text: node.phoneValue},
            {id: 2, name: node.emailTranslate, icon: 'el-icon-message', text: node.emailValue},
            {id: 3, name: node.addressTranslate, icon: 'el-icon-location-information', text: node.addressValue},
          ]
          this.$store.commit('getNode', node)
          this.$store.commit('getNewsList', newsList)
          this.$store.commit('getBizList', bizList)
          this.$store.commit('getAbout', about)
          this.$store.commit('getNavList', navList)
          this.$store.commit('getHomeList', homeList)
          this.$store.commit('getBusinessCategory', businessCategorylist)
          this.$store.commit('getNewsCategory', newsCategorylist)
          this.$store.commit('getRelationList', relationList)
        }
      }).catch(err => {
        console.log('err', err);
        if(err.response.status == 500) {
          this.$store.commit('getsiteCode', 'www')
          this.getSitehome()
        }
      })
    },
    // 获取站点信息
    sitenodeInfo() {
      let siteCode = this.$store.state.siteCode
      sitenodeInfo(siteCode).then((res) => {
        if (res && res.code == 0) {
          console.log(res.data);
        }
      }).catch(err => {
        console.log('err', err);
      })
    },
    // 图片路径转换
    imgTransition(str) {
      return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
    },
    getDateStr(seconds){
      var date = new Date(seconds)
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var currentTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return currentTime
    }
  }
}
</script>
<style lang="scss">
  @import "@/assets/style/reset.scss";
// body {
//   margin: 0;
//   overflow-x: hidden;
// }
#app {
  font-family: 'Open Sans',"Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app_header {
  height: 74px;
}
@media screen and (max-width: 980px) {
  .app_header {
    height: 274px;
  }
}
.service {
  width: 40px;
  height: 40px;
  display: flex;
  border: 3px solid #676363;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 30px;
  color: #3987d9;
  position: fixed;
  z-index: 999;
  bottom: 177px;
  right: 32px;
  cursor: pointer;
  .service_text {
    padding-top: 10px;
    font-size: 18px;
    text-align: center;
    writing-mode: vertical-rl;
    display: none;
  }
}
</style>
